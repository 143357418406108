import fs from 'fs' // ssr
import path from 'path' // ssr

import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

const MainContainer = dynamic(() => import('../components').then(mod => mod.MainContainer))

import { isObjectEmpty } from '../utils'
import getGlobalData from '../services/cacheGlobalData'
import { PageBuilder } from '../components'
import { getAllBuilderPages, getPageBySlug } from '../services'

const Page = ({ pageData }) => {
  const router = useRouter()
  const contentBuilder = pageData?.signaturePageFields?.contentBuilder

  return router?.isFallback ? (
    <div>Loading...........</div>
  ) : (
    <MainContainer>
      {/* SECTION - Content Builder */}
      <PageBuilder data={contentBuilder}></PageBuilder>
    </MainContainer>
  )
}

export default Page

const pagesFilePath = path.join(__dirname, 'temp_pages_cache.json')

/**
 * Serverside (Build-time) Function
 * - gets all pages and make static paths for each one during the build time
 * - getStaticPaths is applicable to dynamic pages like this one [slug].js
 */
export async function getStaticPaths() {
  const allPages = await getAllBuilderPages()

  // Save Pages to temporary file cache
  await fs.writeFileSync(pagesFilePath, JSON.stringify(allPages), err => {
    if (err) console.log('Pages cache save error:', err)
  })

  const paths =
    allPages.map(page => ({
      params: {
        id: page?.databaseId,
        slug: page?.slug,
      },
    })) || []

  return {
    paths,
    fallback: true, // allows previews/new pages on the fly
  }
}

/**
 * Serverside (Build-time) Function
 * After getting all the path (slug)
 * Fetch the respective data from those paths(slug) passed via params
 */
export async function getStaticProps({ params, preview = false }) {
  const slug = params?.slug
  let allPages

  // load pages from temporary file cache
  try {
    allPages = JSON.parse(fs.readFileSync(pagesFilePath, 'utf8'))
  } catch (error) {
    console.log('Pages cache load error:', error)
  }

  let currentPage

  if (preview) {
    currentPage = await getPageBySlug(params?.slug) // Get preview data here
  } else {
    currentPage = allPages?.filter(page => page?.slug === slug)?.[0]
  }

  const pageData = currentPage ? currentPage : {}
  const globalData = await getGlobalData()

  return isObjectEmpty(pageData)
    ? { notFound: true }
    : {
        props: {
          preview,
          pageData,
          globalData,
        },
      }
}
